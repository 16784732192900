import {getBasePath, mergeObjects} from './commonUtil';

export function prodEnvironment(countryCode) {
  let baseConfig = {
    exrEnv: 'prod',
    exrServiceApi: 'https://api.fordcredit.com/',
    adobeSrc: 'https://assets.adobedtm.com/248d63c2ff6b/01cce4ecbac0/launch-EN69254704555044e1a0deab4d4b35f1ff.min.js',

    // OPTIMIZELY VALUES
    optimizelyKey: 'EVSfPEN5w4qJ1FbzVqgLe',
    experimentVariation: 'exp_id_-_93',

    // TIMEOUT VALUES
    warningTimeMilliseconds: '900000',
    sessionEndTimeMilliseconds: '1800000',

    // PREPOPULATE FORM FIELDS
    prepopulateApprovedCustomerTestDataUSA: false,
    prepopulateCreditFreezeCustomerTestDataUSA: false,
    prepopulateApprovedCustomerTestDataCAN: false,
    prepopulateCreditFreezeCustomerTestDataCAN: false,
    prepopulateQuebecCreditFreezeCustomerTestDataCAN: false,

    // WEB COMPONENT VALUES
    appModalURL: 'https://www.qualify.ford.com/webcompbutton/prequal-app-modal.js',
    minifiedCSSURL: 'https://www.qualify.ford.com/webcompbutton/prequal-app.css',

    // TARGET URLS
    specialEventTarget: 'https://www.buyfordnow.com/salesevent',

    // FLAGS
    specialEventFlag: true,
    offersAndIncentivesFlag: false,
    adobeSuiteFlag: false,
    aemFlag: true,
    aemFormFlag: false,
    addressMobileImprovementFlag: false,

    // AEM VALUES
    AEM_AUTHOR_URL: 'https://www.creditbrandauthorlb.ford.com',
    basePath: getBasePath('/finance/qualify', '/finance/prequalify', 'https://www.creditbrandauthorlb.ford.com')
  };
  const targetConfigUSA = {
    exploreVehiclesTarget: 'https://shop.ford.com/showroom/',
    prequalifyTarget: 'https://www.ford.com/finance/prequalify',
    shopTarget: 'https://shop.ford.com/showroom/',
    ocaTarget: 'https://www.ford.com/finance/apply/',
    countryCode: 'USA',
    AEM_PAGE_PATH: '/content/ucl/prequal/ford/en-us/tiles/landingpage',
    AEM_PUBLISHER_URL: 'https://www.ford.com/finance'
  };
  const targetConfigCanada = {
    exploreVehiclesTarget: 'https://shop.ford.ca/showroom/',
    prequalifyTarget: 'https://www.ford.ca/finance/prequalify',
    shopTarget: 'https://shop.ford.ca/showroom/',
    ocaTarget: 'https://www.ford.ca/finance/apply/',
    countryCode: 'CAN',
    AEM_PAGE_PATH: '/content/ucl/prequal/ford/en-ca/tiles/landingpage',
    AEM_PUBLISHER_URL: 'https://www.ford.ca/finance'
  };

  baseConfig = mergeObjects(baseConfig, targetConfigUSA, targetConfigCanada, countryCode);
  return baseConfig;
}
